<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <PnxLogo />
    </b-link>

    <div class="misc-inner pt-5 p-2 p-sm-3">
      <div class="w-100 text-center pt-5">
        <b-avatar
          variant="light-danger"
          size="96"
        >
          <feather-icon
            icon="XIcon"
            size="48"
          />
        </b-avatar>
        <h2 class="mt-1 mb-1 text-danger">
          Payment failed
        </h2>
        <p class="mb-2">
          We’re not able to complete this transaction at the moment.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  BAvatar,
  BButton,
  BImg,
  BLink,
} from 'bootstrap-vue'
import PnxLogo from '@/views/ui/Pnx.vue'

export default {
  name: 'PaymentSuccess',
  components: {
    BAvatar,
    BLink,
    PnxLogo,
    BButton,
    BImg,
  },
  data() {
    /* eslint-disable global-require */
    return {
      imgUrl: require('@/assets/images/pages/error.svg'),
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
